

.navbar-logout {
  display: none;
  margin-left: 86%;
  position: absolute;
  margin-top: 100px;
  background-color: #121212;
  padding: 15px 20px 15px 25px;
  box-shadow: rgb(6, 10, 10);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  width: 14%;
}
#navbar-avatar:hover ~ .navbar-logout {
  display: block;
}
.navbar-logout:hover {
  display: block;
}
::-webkit-file-upload-button {
  padding: 1em;
  border-radius: 5px;
}
.objectgroupdiv {
  display:flex;
  flex-direction: row;
}